<template>
    <div>
        这是一个订单支付的页面
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>

</style>